export default {
  beforeMount(el) {
    const handleInput = (event) => {
      const input = event.target.value.trim() // Eliminar espacios al principio y al final
      if (input !== event.target.value) {
        event.target.value = input
        event.target.dispatchEvent(new Event('input')) // Emitir evento input para actualizar Vue
      }
    }

    // Escuchar tanto 'input' como 'blur'
    el.addEventListener('input', handleInput)
    el.addEventListener('blur', handleInput)
  },
  unmounted(el) {
    // Asegurarse de remover los listeners correctamente
    el.removeEventListener('input', null)
    el.removeEventListener('blur', null)
  }
}
