<template>
  <transition name="fade">
    <div v-if="isVisible" class="backdrop" @click="toggleSidebar"></div>
  </transition>
  <transition name="slide">
    <div v-if="isVisible" class="shopping-cart-sidebar">
      <div class="sidebar-content d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="me-3">Carrito</h4>
            <a class="link-offset-2 cursor-pointer" @click="clearCart">Vaciar Carrito</a>
          </div>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="toggleSidebar"
          ></button>
        </div>
        <div
          v-if="!isClientCredit"
          class="alert p-3 mx-2 mt-2"
          :class="{
            'alert-warning': shoppingStore.cartTotalPrice < 1500,
            'alert-success': shoppingStore.cartTotalPrice >= 1500
          }"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="shoppingStore.cartTotalPrice >= 1500"
          >
            <span
              >¡Felicidades! Tu carrito supera los <strong>$1500</strong>, sigue comprando para
              completar tu pedido.</span
            >
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="shoppingStore.cartTotalPrice < 1500"
          >
            <span
              >Agrega
              <strong>{{ $formatcurrency(1500 - shoppingStore.cartTotalPrice) }}</strong> para poder
              <strong>realizar tu pedido</strong></span
            >
            <span class="fw-semibold">{{ progressPercentage.toFixed(2) }}% </span>
          </div>
          <div class="progress mb-3 mt-2" v-if="shoppingStore.cartTotalPrice < 1500">
            <div
              class="progress-bar"
              :style="{ width: progressPercentage + '%' }"
              role="progressbar"
              aria-valuenow="progressPercentage"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div
          v-if="isClientCredit && getBalanceLogicState"
          class="alert p-3 mx-2 mt-2 fs-5"
          :class="{
            'alert-light': remainingBalance > 0,
            'alert-warning': remainingBalance <= 0
          }"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="remainingBalance <= 0"
          >
            <span
              >Ha excedido su saldo disponible en
              <strong>{{ $formatcurrency(Math.abs(remainingBalance)) }}</strong
              >. Si quiere realizar esta compra el importe adicional será cargado automáticamente a
              su cuenta.</span
            >
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="remainingBalance > 0"
          >
            <span
              >Tu saldo disponible es de
              <strong>{{ $formatcurrency(remainingBalance) }}</strong>
            </span>
            <span class="fw-semibold">{{ progressBalanceRemaining.toFixed(2) }}% </span>
          </div>
          <div class="progress mb-3 mt-2" v-if="remainingBalance > 0">
            <div
              class="progress-bar"
              :style="{ width: progressBalanceRemaining + '%' }"
              role="progressbar"
              aria-valuenow="progressBalanceRemaining"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="flex-grow-1 p-3 overflow-auto">
          <ProductCartList
            hideOriginalFooter
            @showProductDetail="showProductDetail"
            @addOne="addOne"
            @changeAmount="changeAmount"
            @subtractOne="subtractOne"
            @removeProduct="removeProduct"
          />
        </div>
        <div class="footer p-3 border-top">
          <div class="d-flex justify-content-between align-items-center">
            <span>Subtotal:</span>
            <span>{{ $formatcurrency(shoppingStore.cartSubTotalPrice) }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center text-primary-app">
            <span>Descuento <span v-if="isClientCredit">20%</span>:</span>
            <span>{{ $formatcurrency(shoppingStore.cartTotalDiscount) }}</span>
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="!isClientCredit && !getBalanceLogicState"
          >
            <strong>Total a pagar:</strong>
            <strong>
              <span>{{ $formatcurrency(shoppingStore.cartTotalPrice) }}</span>
            </strong>
          </div>
          <div v-else>
            <div class="d-flex justify-content-between align-items-center text-danger">
              <span>Saldo de bono:</span>
              <span>{{ $formatcurrency(userBalance) }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <strong>Total a pagar:</strong>
              <strong>
                <span>{{ $formatcurrency(totalToPay < 0 ? 0 : totalToPay) }}</span>
              </strong>
            </div>
          </div>

          <button class="btn btn-primary-app w-100 mt-3" @click="goToShoppingCartView">
            Continuar
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import ProductCartList from '@/components/products/ProductCartList.vue'
import Swal from 'sweetalert2'
import { computed } from 'vue'
import { useShoppingStore } from '@/stores/shopping'
import { useUserStore } from '@/stores/user'
import router from '@/router'

const shoppingStore = useShoppingStore()
const userStore = useUserStore()

const isVisible = computed({
  get: () => shoppingStore.cartIsVisible,
  set: (newValue) => {
    shoppingStore.updateCartVisibility(newValue)
  }
})
const totalPrice = computed(() => shoppingStore.cartTotalPrice)
const progressPercentage = computed(() => Math.min((totalPrice.value / 1500) * 100, 100))
const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const progressBalanceRemaining = computed(() =>
  Math.min((remainingBalance.value / userBalance.value) * 100, 100)
)

const totalToPay = computed(() => shoppingStore.cartTotalPrice - userBalance.value)

const isClientCredit = computed(() => userStore.isClientCredit)

const addOne = (id) => {
  shoppingStore.addOne(id)
}

const changeAmount = (id, amount) => {
  shoppingStore.changeAmount(id, amount)
}

const subtractOne = (id) => {
  shoppingStore.subtractOne(id)
}

const removeProduct = (index) => {
  shoppingStore.deleteItem(index)
}

const toggleSidebar = () => {
  isVisible.value = !isVisible.value
}

const showProductDetail = (id) => {
  router.push({ name: 'productDetail', params: { id } })
}

const clearCart = () => {
  Swal.fire({
    title: '¿Estás seguro de vaciar el carrito?',
    html: `
    <p>Todos los productos agregados serán eliminados y esta acción no se puede deshacer.</p>
    <p>¿Deseas continuar?</p>
  `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff6600',
    cancelButtonColor: '#9499a1',
    confirmButtonText: 'Sí, vaciar carrito',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      shoppingStore.emptyCart()
      shoppingStore.hideCart()
    }
  })
}

const goToShoppingCartView = () => {
  router.push({ name: 'shoppingCart' })
  shoppingStore.hideCart()
}
</script>

<style scoped>
.shopping-cart-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .shopping-cart-sidebar {
    width: 100%;
  }
}

.backdrop {
  display: none;
}

@media (min-width: 992px) {
  .backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40vw);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1050;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
