import { defineStore, getActivePinia } from 'pinia'
import { ref } from 'vue'
import router from '@/router'
import permissionsByUserType from '@/constants/config/permissions'
import { useUserStore } from '@/stores/user'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: ref(null),
    refresh_token: ref(null),
    urlToGoBeforeLogin: ref(null),
    alreadyLandBeforeLogin: ref(null)
  }),
  getters: {
    getAlreadyLandBeforeLogin() {
      return this.alreadyLandBeforeLogin
    },
    getUrlToGoBeforeLogin() {
      return this.urlToGoBeforeLogin
    },
    hasUrlToGoBeforeLogin() {
      return !!this.urlToGoBeforeLogin
    },
    isLoggedIn() {
      return !!this.token
    },
    hasPermission: () => (routeName) => {
      const userStore = useUserStore()
      const userType = userStore.userType
      const permissions = permissionsByUserType[userType] || permissionsByUserType.default
      return permissions.includes(routeName)
    }
  },
  actions: {
    defineUrlToGoBeforeLogin(routeName) {
      this.urlToGoBeforeLogin = routeName
      this.alreadyLandBeforeLogin = !routeName
    },
    alreadyLand() {
      this.alreadyLandBeforeLogin = false
    },
    login(response) {
      this.token = response.data.access_token
      this.refresh_token = response.data.refresh_token
    },
    logout() {
      const userStore = useUserStore()
      const stores = getActivePinia()
      stores._s.forEach((store) => store.$reset())
      this.urlToGoBeforeLogin = null
      this.alreadyLandBeforeLogin = null
      userStore.updateHasSeenVideo(true)
      router.push({ name: 'login' })
    }
  },
  persist: true
})
