<template>
  <div class="d-flex flex-column min-vh-100">
    <!-- Navbar -->
    <NavbarComponent class="navbarFixed" id="navBar" v-if="route.name !== 'login'" />
    <SidebarComponent :id="route.name !== 'login' ? 'sidebar' : 'sidebarNoLogin'" />
    <CartSideMenu />
    <!-- Main Content -->
    <main class="flex-fill" :class="route.name !== 'login' ? 'main' : 'mainNoLogin'">
      <router-view class="container pb-5" />
    </main>

    <!-- Footer -->
    <FooterComponent />
    <template v-if="!route.path.includes('admin') && !isRoutePublic">
      <CartButton />
    </template>
  </div>
</template>

<script setup>
import NavbarComponent from '@/components/layout/navigation/navbar/BaseNavbar.vue'
import FooterComponent from '@/components/layout/navigation/footer/BaseFooter.vue'
import SidebarComponent from '@/components/layout/navigation/SidebarComponent.vue'
import CartSideMenu from '@/components/layout/cart/CartSideMenu.vue'
import CartButton from '@/components/layout/cart/CartButton.vue'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const publicRoutesRequiredValidations = [
  'product_detail_public',
  'product_detail_only_list_pack_public',
  'category_public'
]
const isRoutePublic = computed(() => publicRoutesRequiredValidations.includes(route.name))

const navbarHeight = ref(0)

const sizeWindowBaseLayout = () => {
  const navbar = document.querySelector('#navBar')
  if (navbar) {
    navbarHeight.value = navbar.offsetHeight
    document.documentElement.style.setProperty('--navbar-height', `${navbarHeight.value + 12}px`)
  }
}

onMounted(() => {
  window.addEventListener('resize', sizeWindowBaseLayout)
  if (route.name !== 'login') {
    sizeWindowBaseLayout()
  }

})

onUnmounted(() => {
  window.removeEventListener('resize', sizeWindowBaseLayout)
})

watch(
  () => route.name,
  (newRoute) => {
    if (newRoute !== 'login') {
      nextTick(() => {
        sizeWindowBaseLayout()
      })
    }
  }
)
</script>

<style scoped>
/* Ensure main takes up all available space */
.main {
  flex: 1;
  padding-top: var(--navbar-height);
}

.mainNoLogin {
  flex: 1;
  padding-top: 1.5rem;
}

#sidebar {
  padding-top: var(--navbar-height);
}

#sidebarNoLogin {
  padding-top: 2rem;
}

/* Custom styling for the footer if needed */
footer {
  background-color: #f8f9fa;
  padding: 1rem 0;
}

/* Global CSS to set the navbar height variable */
:root {
  --navbar-height: 56px; /* Default height for the navbar */
}

/* Override the padding-top in main based on actual navbar height */
@media (min-width: 992px) {
  .main {
    padding-top: calc(var(--navbar-height));
  }
}
</style>
