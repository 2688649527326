export default {
  mounted(el, binding) {
    let timeout
    const delay = binding.arg || 300 // Usar el argumento de la directiva como el tiempo de espera

    const handler = (event) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        binding.value(event)
      }, delay)
    }

    el.addEventListener('input', handler)

    el.__debounce_handler__ = handler
  },
  unmounted(el) {
    if (el.__debounce_handler__) {
      el.removeEventListener('input', el.__debounce_handler__)
      delete el.__debounce_handler__
    }
  }
}
