export default {
  beforeMount(el, { value: updatePhone }, vnode) {
    const formatPhoneNumber = (number) => {
      // Elimina todo lo que no sea dígito
      const cleanNumber = number.replace(/\D+/g, '')

      // Formato: +LADA XX XXXX XXXX (solo para visualización)
      let formattedNumber = cleanNumber
      if (cleanNumber.length > 0) {
        formattedNumber = `${cleanNumber.slice(0, 2)} `
      }
      if (cleanNumber.length > 2) {
        formattedNumber += `${cleanNumber.slice(2, 4)} `
      }
      if (cleanNumber.length > 4) {
        formattedNumber += `${cleanNumber.slice(4, 8)} `
      }
      if (cleanNumber.length > 8) {
        formattedNumber += cleanNumber.slice(8, 12)
      }

      return { cleanNumber, formattedNumber }
    }

    const handleInput = (event) => {
      const { cleanNumber, formattedNumber } = formatPhoneNumber(event.target.value)

      // Actualiza visualmente el input con el número formateado
      event.target.value = formattedNumber

      // Invoca la función de actualización del modelo pasando cleanNumber
      if (typeof updatePhone === 'function') {
        updatePhone(cleanNumber) // actualiza el modelo externamente
      }
    }

    el.addEventListener('input', handleInput)
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
