// src/directives/minValue.js
export default {
  beforeMount(el) {
    el.addEventListener('input', () => {
      const value = parseFloat(el.value)
      if (value < 1 || isNaN(value)) {
        el.value = 1
        el.dispatchEvent(new Event('input')) // Dispatch the input event to update the v-model
      }
    })
  }
}
