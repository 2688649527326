import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore('config', {
  state: () => ({
    sidebarVisible: ref(false),
    adminSidebarVisible: ref(false)
  }),
  getters: {
    getSidebarVisibleAdmin() {
      return this.adminSidebarVisible
    },
    getsidebarVisible() {
      return this.sidebarVisible
    }
  },
  actions: {
    updateAdminSidebarVisibility(value) {
      this.adminSidebarVisible = value
    },
    toogleAdminSidebar() {
      this.adminSidebarVisible = !this.adminSidebarVisible
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible
    }
  }
})
