export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      const input = event.target.value
      event.target.value = input.replace(/\D/g, '') // Reemplazar todo lo que no sea un dígito
      if (input !== event.target.value) {
        event.target.dispatchEvent(new Event('input')) // Emitir un evento input para actualizaciones en Vue
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
