<template>
  <div
    class="row mb-5"
    v-for="(product, index) in cartProductContent"
    :key="product.item.id + index"
  >
    <div class="col-3">
      <img
        @click="showProductDetail(product.item.id)"
        :src="product.item.image"
        class="img-fluid cursor-pointer"
        alt=""
      />
    </div>
    <div class="col-8">
      <p>{{ product.item.title }}</p>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div>
          <span class="fw-semibold">Codigo: {{ product.item.code }}</span>
        </div>
        <div>
          <span class="fw-semibold">Clave: {{ product.item.key }}</span>
        </div>
      </div>
      <div class="row align-items-center z-1">
        <div class="col-8 col-md-12">
          <div class="input-group mb-3">
            <button
              class="btn btn-outline-secondary border z-1"
              type="button"
              @click="subtractOne(product.item.id)"
            >
              <font-awesome-icon icon="fa-solid fa-minus" />
            </button>
            <input
              type="number"
              class="form-control text-center"
              v-min-value
              v-numeric
              @keyup.enter="changeAmount(product.item.id, product.amount)"
              @blur="changeAmount(product.item.id, product.amount)"
              v-model="product.amount"
            />
            <button
              class="btn btn-outline-secondary border z-1"
              type="button"
              @click="addOne(product.item.id)"
            >
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>
        </div>
        <div class="col-4 col-md-12 text-end" v-if="product.item.discount > 0">
          <p class="text-decoration-line-through red-strike">
            {{ $formatcurrency(product.item.price) }}
          </p>
          <p>{{ $formatcurrency(product.item.price_discount) }}</p>
        </div>
        <div class="col-4 col-md-12 text-end" v-else>
          <p>{{ $formatcurrency(product.item.price) }}</p>
        </div>
      </div>
    </div>
    <div class="col-1">
      <font-awesome-icon
        class="cursor-pointer text-app"
        icon="fa-solid fa-trash"
        size="lg"
        @click="removeProduct(index)"
      />
    </div>
  </div>
  <div v-if="!hideOriginalFooter">
    <div v-if="!isClientCredit && !getBalanceLogicState">
      <div class="row">
        <div class="col-6"><span class="fs-6 text-app">Entrega</span></div>
        <div class="col-6 text-end">
          <span class="fs-6 text-app" v-text="'Gratis'"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-6"><span class="fs-6 text-app">Subtotal</span></div>
        <div class="col-6 text-end">
          <span
            class="fs-6 text-app"
            v-text="`${$formatcurrency(shoppingStore.cartSubTotalPrice)}`"
          ></span>
        </div>
      </div>
      <div class="row text-primary-app">
        <div class="col-6"><span class="fs-6">Descuento</span></div>
        <div class="col-6 text-end mb-0">
          <span class="fs-6" v-text="`${$formatcurrency(shoppingStore.cartTotalDiscount)}`"></span>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-6">
          <span
            :class="{
              'text-black': !cartTotalPriceIsLessThan1500,
              'text-danger': cartTotalPriceIsLessThan1500
            }"
            class="fw-semibold fs-5"
            >TOTAL</span
          >
        </div>
        <div
          class="col-6 text-end"
          :class="{
            'text-black': !cartTotalPriceIsLessThan1500,
            'text-danger': cartTotalPriceIsLessThan1500
          }"
        >
          <span
            class="fw-semibold fs-5"
            v-text="`${$formatcurrency(shoppingStore.cartTotalPrice)}`"
          ></span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-6"><span class="fs-6 text-app">Subtotal</span></div>
        <div class="col-6 text-end mb-3">
          <span
            class="fs-6 text-app"
            v-text="`${$formatcurrency(shoppingStore.cartSubTotalPrice)}`"
          ></span>
        </div>
      </div>
      <div class="row text-primary-app">
        <div class="col-6">
          <span class="fs-6">Descuento <span v-if="isClientCredit">20%</span></span>
        </div>
        <div class="col-6 text-end mb-0">
          <span class="fs-6" v-text="`${$formatcurrency(shoppingStore.cartTotalDiscount)}`"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-6"><span class="fs-6 text-danger">Saldo de bono</span></div>
        <div class="col-6 text-end text-danger mb-0">
          <span class="fs-6" v-text="`${$formatcurrency(userBalance)}`"></span>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-6">
          <span
            :class="{
              'text-black': !cartTotalPriceIsLessThan1500,
              'text-danger': cartTotalPriceIsLessThan1500
            }"
            class="fw-semibold fs-5"
            >TOTAL A PAGAR</span
          >
        </div>
        <div
          class="col-6 text-end"
          :class="{
            'text-black': !cartTotalPriceIsLessThan1500,
            'text-danger': cartTotalPriceIsLessThan1500
          }"
        >
          <span
            class="fw-semibold fs-5"
            v-text="`${$formatcurrency(totalToPay < 0 ? 0 : totalToPay)}`"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useShoppingStore } from '@/stores/shopping'
import { useUserStore } from '@/stores/user'
import { computed, toRefs } from 'vue'

const props = defineProps({
  hideOriginalFooter: { type: Boolean, required: false, default: false }
})

const { hideOriginalFooter } = toRefs(props)

const emit = defineEmits([
  'subtractOne',
  'addOne',
  'changeAmount',
  'removeProduct',
  'showProductDetail'
])

const cartTotalPriceIsLessThan1500 = computed(() => {
  return shoppingStore.cartTotalPrice < 1500
})

function subtractOne(id) {
  const amount = cartProductContent.value.find((product) => product.item.id === id).amount
  if (amount === 1) return
  emit('subtractOne', id)
}

function addOne(id) {
  emit('addOne', id)
}

function changeAmount(id, amount) {
  emit('changeAmount', id, amount)
}

function removeProduct(index) {
  emit('removeProduct', index)
}

function showProductDetail(id) {
  emit('showProductDetail', id)
}

const shoppingStore = useShoppingStore()
const userStore = useUserStore()

const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const isClientCredit = computed(() => userStore.isClientCredit)
const totalToPay = computed(() => shoppingStore.cartTotalPrice - userBalance.value)
const cartProductContent = computed(() => {
  return shoppingStore.cartContent
})
</script>

<style lang="scss" scoped></style>
